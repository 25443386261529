/*Navigation Bar*/
.color-black {
    color: #fff;
}

.divider-nav {
    width: 100%;
    height: 1px;
    background-color: #3c556d;
    margin: 5px 0px;
}

a.color-black {
    color: #fff;
}

span[ class^="flaticon"] {
    display: inline-block;
    margin: 0px 10px 0px 10px;
    color: #0086ff;
}

span.flaticon-shopping-basket-button::before {
    margin: 0px 10px;
}

span.flaticon-google-drive-document::before {
    margin: 0px 10px;
}

span.flaticon-downwards-arrow-key {
    /* color: #fff; */
    color: #a7b5c2;
}

.main-nav-1 span.flaticon-downwards-arrow-key::before {
    font-size: 12px !important;
}

.flaticon-home-button::before {
    margin: 0px 10px;
}

.main-nav-1 span.flaticon-screen-with-rounded-corners::before {
    margin: 0px 10px;
}

.main-nav-1 span.flaticon-cloud-backup-up-arrow::before {
    margin: 0px 10px;
    font-size: 18px;
    line-height: 14px;
}

.flaticon-show-apps-button::before {
    margin: 0px 10px;
}

.main-nav {
    padding: 10px;
    border-radius: 24px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    position: relative;
    outline: none;
    background-color: #0086ff;
    box-shadow: rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px;
    border: none;
}

.main-nav::before {
    content: '';
    background-color: aliceblue;
    border-radius: 24px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
}

.main-nav-1 {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent;
    width: 100%;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    position: relative;
    outline: none;
    border: none;
    cursor: pointer;
    align-items: center;
}

.main-nav-1::before {
    content: '';
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
}

.main-nav:focus {
    outline: 0;
    color: #666;
}

.main-nav:focus:before {
    -webkit-animation: btn-transition 0.8s ease-out;
    animation: btn-transition 0.8s ease-out;
}

.main-nav span[ class^="flaticon"] {
    display: inline-block;
    margin: 0px 5px;
    color: #fff;
}

.main-nav span.flaticon-right-arrow-forward {
    display: inline-block;
    margin: 0px 5px;
    color: #fff;
}

.main-nav span.flaticon-right-arrow-forward::before {
    font-size: 11px !important;
}

.subOptionChild {
    font-size: 11px;
    color: #8598a7;
    cursor: pointer;
    padding: 3px;
    margin-left: 0rem;
    white-space: nowrap;
}

.subOptionChild span span a {
    color: #8596a7;
}

.subOptionChild>span[class^="flaticon"] {
    color: #8596a7;
}

.subOptionChild>span[class^="flaticon"]::before {
    margin: 0px 10px;
}

.sub-active {
    width: fit-content;
    color: #fea23a !important;
}

.sub-active span span a {
    color: #fea23a;
    font-weight: 700;
}

.sub-active span[class^="flaticon"] {
    color: #fea23a;
}

.sub-active>span>.color-black {
    color: #0086ff;
}

@-webkit-keyframes btn-transition {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }

    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

@keyframes btn-transition {
    50% {
        -webkit-transform: scale(1.5, 1.5);
        transform: scale(1.5, 1.5);
        opacity: 0;
    }

    99% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0.001, 0.001);
        transform: scale(0.001, 0.001);
        opacity: 1;
    }
}

.icon-alert .flaticon-right-arrow-forward:before,
.flaticon-right-arrow-forward:before,
.flaticon-right-arrow-forward:after,
.flaticon-right-arrow-forward:after {
    font-family: Flaticon, sans-serif;
    font-size: 15px !important;
    font-style: normal;
    margin-left: 0px !important;
}

.color-white {
    color: #fff;
}

.color-blue {
    color: #0086ff;
}

.color-fa .flaticon-expand-arrow {
    color: #8596a7 !important;
}

.color-fa .flaticon-expand-arrow::before,
span.flaticon-expand-arrow:before,
span.flaticon-expand-arrow:after,
span.flaticon-expand-arrow:after {
    font-size: 12px !important;
}

.color-fa .flaticon-downwards-arrow-key {
    color: #8596a7 !important;
}

.side-nav {
    overflow: hidden;
}

.side-nav:hover {
    overflow-y: auto;
}


::-webkit-scrollbar {
    width: .5em !important;
    height: 3px
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
}

::-webkit-scrollbar-thumb {
    background-color: #4F7190 !important;
}

.Y6 {
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    letter-spacing: .3px;
    background-color: #34638f;
    border-radius: 4px;
    color: #cae5ff;
    display: inline;
    padding: 4px;
    justify-content: center;
    margin-left: 3px;
}