.btn-v2{
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-v2-primary{
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-v2-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn:focus, .btn:hover {
    text-decoration: none;
}
.btn-v2-primary-outline{
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
}
.btn-v2-primary-outline:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-v2-default{
    color: #fff;
    border-radius: 4px;
    background-image: -moz-linear-gradient( -90deg, rgb(255,198,0) 0%, rgb(255,156,0) 100%);
    background-image: -webkit-linear-gradient( -90deg, rgb(255,198,0) 0%, rgb(255,156,0) 100%);
    background-image: -ms-linear-gradient( -90deg, rgb(255,198,0) 0%, rgb(255,156,0) 100%);
    background-image: linear-gradient( -90deg, rgb(255,198,0) 0%, rgb(255,156,0) 100%);
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
}
.btn-v2-default-outline{
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}
.btn-v2-secondary{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-v2-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}
.btn-v2-secondary-outline{
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}
.btn-v2-secondary-outline:hover{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.btn-v2-danger{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-v2-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-v2-danger-outline{
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
}
.btn-v2-danger-outline:hover{
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-v2-success{
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-v2-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}
.btn-v2-success-outline{
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
}
.btn-v2-success-outline:hover{
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-v2-warning{
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-v2-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-v2-warning-outline{
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
}
.btn-v2-warning-outline:hover{
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-v2-info{
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-v2-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}
.btn-v2-info-outline{
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
}
.btn-v2-info-outline:hover{
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.btn-v2-light{
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-v2-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.btn-v2-light-outline{
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}
.btn-v2-light-outline:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}
.btn-v2-dark{
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-v2-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}
.btn-v2-dark-outline{
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}
.btn-v2-dark-outline:hover{
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}
.btn-v2-link{
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
}
.btn-v2-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}
.btn-v2:not(:disabled):not(.disabled) {
    cursor: pointer;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
