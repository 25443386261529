.container-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f2f2f2;
  overflow: hidden;
}

.frame {
  width: 100%;
  overflow: hidden; 
}

.box-container {
  display: flex;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
}

.job-card-container {
    width: 33% !important;
    margin-left: 12px !important;
    height: 100%;
}

.job-card-container:last-child{
    margin-right: 0px !important;
}


.button-2-container {
  display: flex;
  height: 74px;
  align-items: center;
  margin-top: 13px;
}

.button-2 {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  background: #c1c8e9;
  margin: 6px;
  cursor: pointer;
}

.active-dot {
    width: 15px !important;
    height: 15px !important;
  background: #1d45ff !important;
}

.search-bar-wrapper-design .div-root-70{
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 200px !important;
  height: 38px !important
}
.search-bar-wrapper-design .wrapper-for-search{
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 200px !important;
  height: 38px !important
}