/*
Add Service CSS
*/
/* .input-label{
    font-size: 12px;
    font-weight: 500;
} */
.checkbox-with-dropdown{
    display: block;
}
.checkbox-with-dropdown input[type="checkbox"]{
    display: inline-block;
}
.checkbox-with-dropdown label{
    display: inline-block;
}
.head-panel-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: .75rem 0rem; */
}
.seprator{
    height: 1px;
    margin: 10px 0;
    width: 100%;
    background-color: #f4f4f4;
}

.service-basic-details{
    display: flex;
    align-items: center;
    justify-content: center;
}
.service-basic-details .service-name .text-section{
    margin: 5px;
}
.s_name__heading{
    line-height: 1;
}
.service-name{
    display: flex;
    align-items: center;
    margin: 0px 0px;
}
.round-check-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #62E187;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 0px 0px .5rem;
}
.round-check-box .flaticon-check-symbol{
    color: #62E187!important;
}
.text-section{
    margin: 0px 0px 0px 5px;
    line-height: 1;
}
.text-section p{
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
}
.text-section span{
    color: #666;
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
}
.sub-heading{
    font-size: 11px;
    line-height: 1;
    margin-right:5px ;
}
.color-dark{
    color: #000!important;
    font-weight: 400!important;
}
.card-add-service{
    border: 1px solid #e7e7eb;
    border-radius: 8px;
    padding: 0px 0px 10px;
    background-color: #fff;
    overflow: hidden;
}
.service-completion {
    display: flex;
    align-items: baseline;
}
.card-add-service-head{
    background-color: #f9f9f9;
    border-bottom: 1px solid #e7e7eb;
    height: 50px;
    display: flex;
    align-items: center;
    /* padding: 10px; */
}
.card-add-service-head h4.mainHeading{
    font-size: 13px;
    font-weight: 400;
    color: #686868;
    line-height: 1.5;
}
.card-body-flex{
    border-radius: 0px 0px 8px 8px;
    padding-bottom: 10px;
}
.head-div{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
}
.head-div div:nth-child(2){
    margin-bottom: 0px!important;
}
.input-radio-d-flex > div {
        display: flex!important;
        align-items: center;
        background-color: #fbfbfb;
        padding: 1rem;
        border-bottom: 1px solid #dedede;
        margin-bottom: 0px!important;
}
.pd-2{
    padding: 1rem;
}
.with-header{
    background-color: #fbfbfb;
    border-bottom: 1px solid #dedede;
    margin-bottom: 0rem!important;
    margin-top: 0rem!important;
}
.grid-container > div > div{
    margin-bottom: 0rem!important;
}
.bold-text-purple{
    font-size: 36px;
    font-weight: 900;
    color: #5262c8!important;
    
    align-self: flex-start;
    margin-top: -0.7rem;
}
.go-to-dashboard{
    width:190px
}
.go-to-dashboard a:visited{
    color: #fff;
}
.bold-text-purple .flaticon-circle-with-check-symbol{
    color: #5262c8!important;
    margin: 0px 5px 0px 0px;
}
.bold-text-purple .flaticon-circle-with-check-symbol:before, .flaticon-circle-with-check-symbol:before,.flaticon-circle-with-check-symbol:after,
.flaticon-circle-with-check-symbol:after {
    font-family: Flaticon,sans-serif;
    font-size: 25px!important;
    font-style: normal;
    margin-left: 0px!important;
}
.sentence-text-purple{
    color: #525a8c;
    font-size: 24px;
    
    font-weight: 900;
    display: inline-block;
    margin-right: 5px;
    line-height: 1.2;
    margin-bottom: 1rem;
}
.sentence-text-black{
    color: #000;
    font-size: 18px;
    
    font-weight: 900;
    line-height: 1.2;
}
.color-purple{
    color: #525a8c;
}
.section-completion{
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.service-name-section{
    display: flex;
    padding-top: 32px;
    align-items: center;
}
.service-name-section .service-name{
    margin: 0px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1;
}
.tagline{
    font-size: 11px;
    color: #888;
    
    line-height: 1;
}
.span-class{
    line-height: 1;
    margin: 0px 5px;
}
.alert-card{
    width: 45rem;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 8px;
    padding: 1rem;
    position: fixed;
    top: 30rem;
    left: 55rem;
    height: 15rem;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index:  999999999;
}
.icon-alert{
    align-self: flex-start;
}
.icon-alert  .flaticon-round-info-button:before, .flaticon-round-info-button:before,.flaticon-round-info-button:after, .flaticon-round-info-button:after {
    font-family: Flaticon,sans-serif;
    font-size: 25px!important;
    font-style: normal;
    margin-left: 0px!important;
}

.alert-text-box{
    align-self: flex-start;
}
.text{
    font-size: 13px;
    color: #686868;
    line-height: 1;
}
.main-text-with-option{
    font-size: 13px;
    color: #000;
    line-height: 3;
}
.main-text-with-option a{
    color: #525a8c;
}
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.3);
    height: 100%;
    z-index: 9999999;
}
.btn-alert{
    border: none;
    background-color: transparent;
    color: #0095da!important;
}
.error-display> div{
    margin-bottom: 0px!important;
}
.section-m-t-b{
    margin-top: 20px;
}
.content-alert-box{
    display: flex;
}