.chipnew {
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    font-weight: 600;
    cursor: default;
    background-color: #f0f0f0;
    color: #333;
}

/* Size Variants */
.chip-small {
    padding: 0.25rem 0.5rem;
    font-size: 8px;
}

.chip-normal {
    padding: 0.5rem 1rem;
    font-size: 10px;
}

.chip-large {
    padding: 0.75rem 1.5rem;
    font-size: 14px;
}

.chip-avatar {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
}

.chip-icon {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
}

.chip-label {
    line-height: 1;
}

.chip-clickable {
    cursor: pointer;
    transition: background-color 0.3s;
}

.chip-clickable:hover {
    background-color: #e0e0e0;
}

/* Variants */
.chip-primary {
    background-color: #007bff;
    color: white;
}

.chip-secondary {
    background-color: #6c757d;
    color: white;
}

.chip-tertiary {
    background-color: #17a2b8;
    color: white;
}

.chip-success {
    background-color: #E6FBEA;
    color: #2EBE79!important;
}

.chip-failed {
    background-color: #dc3545;
    color: white;
}

.chip-pending {
    background-color: #ffc107;
    color: white;
}

.chip-resume {
    background-color: #20c997;
    color: white;
}

.chip-revoked {
    background-color: #343a40;
    color: white;
}

.chip-disconnected{
    background-color: #FCF6E1;
    color: #E1941D;
}