// The color that will be at the beginning and the end of the loop.
$firstColor: #0057B8;

// The list of all the used colors. You can add or remove colors, the animation stays fluid. The $first-color must remain at the end of the list though.
$listColors: #F11E4A, #237, #229C79, #F8A527, #266D7F, $firstColor;

$delayCoeff: 0.04s; 

.main-container-error {
  background-color: transparent;
  height: 90vh;
  display: grid;
  align-content: center;
  justify-content: center;  
  text-align: center;
}

.svg {
  display: inline-block;
  padding: 50px;
  text-align: center;
  background-color: #FFF;
  border-radius: 10px;
  border: 1px solid #dedede;
}
.svg h1{
  font-size: 60px;
    color: #bbb;
    font-weight: 300;
}
#svg {
  display: inline-block;
  vertical-align: middle;
  fill: $firstColor;
  line-height: 300px;
  
  @for $i from 1 through 58 {

      path:nth-child(#{$i}) {
        -webkit-animation: pweek 5s linear infinite;
        animation: pweek 5s linear infinite;
        -webkit-animation-delay: $i*$delayCoeff;
        animation-delay: $i*$delayCoeff;
        
        &:hover {
          -webkit-animation-play-state: paused; /* Chrome, Safari, Opera */
    animation-play-state: paused;
          
        }
      }
   }
}

@-webkit-keyframes pweek {
  0% {
    fill: $firstColor;
  }
  @for $i from 1 through length($listColors) {
    #{$i*(100/length($listColors))}% {
      fill: nth($listColors, $i);
    }
  }
}

@keyframes pweek {
  0% {
    fill: $firstColor;
  }
  @for $i from 1 through length($listColors) {
    #{$i*(100/length($listColors))}% {
      fill: nth($listColors, $i);
    }
  }
}
.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
    .contant_box_404{
      padding-top: 30px;
    }

.contant_box_404 .main-nav a{
      color: #fff;

}
.contant_box_404 .main-nav{
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.contant_box_404 .main-nav a:visited{
  color: #fff;
}

