	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:11
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: "Flaticon", sans-serif;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-add-alarm-button:before { content: "\f100"; }
.flaticon-add-button-inside-black-circle:before { content: "\f101"; }
.flaticon-add-comment-button:before { content: "\f102"; }
.flaticon-add-label-button:before { content: "\f103"; }
.flaticon-add-plus-button:before { content: "\f104"; }
.flaticon-add-to-queue-button:before { content: "\f105"; }
.flaticon-add-user-button:before { content: "\f106"; }
.flaticon-adjust-button-with-plus-and-minus:before { content: "\f107"; }
.flaticon-airplane-mode-on-symbol:before { content: "\f108"; }
.flaticon-android-logo:before { content: "\f109"; }
.flaticon-android-logo-1:before { content: "\f10a"; }
.flaticon-apple-accessibility:before { content: "\f10b"; }
.flaticon-attachment-clip:before { content: "\f10c"; }
.flaticon-auto-white-balance:before { content: "\f10d"; }
.flaticon-automatic-brightness:before { content: "\f10e"; }
.flaticon-automatic-flash-symbol:before { content: "\f10f"; }
.flaticon-back-arrow:before { content: "\f110"; }
.flaticon-backspace-arrow:before { content: "\f111"; }
.flaticon-bed-quilt:before { content: "\f112"; }
.flaticon-been-here-marker:before { content: "\f113"; }
.flaticon-birthday-cake:before { content: "\f114"; }
.flaticon-black-bubble-speech:before { content: "\f115"; }
.flaticon-black-check-box:before { content: "\f116"; }
.flaticon-black-check-box-with-white-check:before { content: "\f117"; }
.flaticon-black-envelope-email-symbol:before { content: "\f118"; }
.flaticon-black-keyboard-with-white-keys:before { content: "\f119"; }
.flaticon-black-plane:before { content: "\f11a"; }
.flaticon-blank-check-box:before { content: "\f11b"; }
.flaticon-blank-frame:before { content: "\f11c"; }
.flaticon-blank-square:before { content: "\f11d"; }
.flaticon-blogger-logo:before { content: "\f11e"; }
.flaticon-blueetooth-logo:before { content: "\f11f"; }
.flaticon-bluetooth-connected:before { content: "\f120"; }
.flaticon-bluetooth-disabled-button:before { content: "\f121"; }
.flaticon-bluetooth-searching-signal-indicator:before { content: "\f122"; }
.flaticon-bluetooth-settings:before { content: "\f123"; }
.flaticon-bluetooth-signal-indicator:before { content: "\f124"; }
.flaticon-bookmark-button:before { content: "\f125"; }
.flaticon-bookmark-button-1:before { content: "\f126"; }
.flaticon-bookmark-outline:before { content: "\f127"; }
.flaticon-bookmark-ribbon:before { content: "\f128"; }
.flaticon-briefcase-download-button-with-downwards-arrow:before { content: "\f129"; }
.flaticon-briefcase-with-tick-inside:before { content: "\f12a"; }
.flaticon-brightness-control:before { content: "\f12b"; }
.flaticon-brochure-with-three-sections:before { content: "\f12c"; }
.flaticon-bubble-speech-with-three-lines:before { content: "\f12d"; }
.flaticon-bug-report-button:before { content: "\f12e"; }
.flaticon-burn-button:before { content: "\f12f"; }
.flaticon-button-on:before { content: "\f130"; }
.flaticon-call-forwarding:before { content: "\f131"; }
.flaticon-call-made-right-arrow:before { content: "\f132"; }
.flaticon-call-received-arrow:before { content: "\f133"; }
.flaticon-camera:before { content: "\f134"; }
.flaticon-camera-diaphragm:before { content: "\f135"; }
.flaticon-cancel-button:before { content: "\f136"; }
.flaticon-caps-lock-button:before { content: "\f137"; }
.flaticon-caps-lock-button-1:before { content: "\f138"; }
.flaticon-car-changing-lanes:before { content: "\f139"; }
.flaticon-car-directions:before { content: "\f13a"; }
.flaticon-car-front:before { content: "\f13b"; }
.flaticon-cell-phone-high-signal-indicator:before { content: "\f13c"; }
.flaticon-cell-phone-vibration:before { content: "\f13d"; }
.flaticon-cell-phone-with-blank-screen:before { content: "\f13e"; }
.flaticon-change-power-options:before { content: "\f13f"; }
.flaticon-chat-bubble:before { content: "\f140"; }
.flaticon-chat-bubbles:before { content: "\f141"; }
.flaticon-check-box:before { content: "\f142"; }
.flaticon-check-symbol:before { content: "\f143"; }
.flaticon-chemistry-polymer:before { content: "\f144"; }
.flaticon-circle-outline:before { content: "\f145"; }
.flaticon-circle-with-check-symbol:before { content: "\f146"; }
.flaticon-circles-extend-button:before { content: "\f147"; }
.flaticon-circumference:before { content: "\f148"; }
.flaticon-city-buildings-silhouette:before { content: "\f149"; }
.flaticon-clapperboard:before { content: "\f14a"; }
.flaticon-clapperboard-1:before { content: "\f14b"; }
.flaticon-clear-button:before { content: "\f14c"; }
.flaticon-clipboard-paste-button:before { content: "\f14d"; }
.flaticon-clock-with-white-face:before { content: "\f14e"; }
.flaticon-close-button:before { content: "\f14f"; }
.flaticon-closed-caption-logo:before { content: "\f150"; }
.flaticon-cloud-backup-up-arrow:before { content: "\f151"; }
.flaticon-cloud-done-symbol:before { content: "\f152"; }
.flaticon-cloud-download:before { content: "\f153"; }
.flaticon-cloud-off:before { content: "\f154"; }
.flaticon-cloud-outline:before { content: "\f155"; }
.flaticon-cloud-symbol-inside-a-circle:before { content: "\f156"; }
.flaticon-compass-with-white-needles:before { content: "\f157"; }
.flaticon-connection-indicator:before { content: "\f158"; }
.flaticon-copy-content:before { content: "\f159"; }
.flaticon-create-group-button:before { content: "\f15a"; }
.flaticon-create-new-pencil-button:before { content: "\f15b"; }
.flaticon-credit-card:before { content: "\f15c"; }
.flaticon-crop-button:before { content: "\f15d"; }
.flaticon-crop-tool-button:before { content: "\f15e"; }
.flaticon-cut-content-button:before { content: "\f15f"; }
.flaticon-dark-cloud:before { content: "\f160"; }
.flaticon-developer-mode-for-smartphones-and-tablets:before { content: "\f161"; }
.flaticon-device-connected:before { content: "\f162"; }
.flaticon-device-connected-1:before { content: "\f163"; }
.flaticon-disc-is-full:before { content: "\f164"; }
.flaticon-do-not-disturb-rounded-sign:before { content: "\f165"; }
.flaticon-double-tick-indicator:before { content: "\f166"; }
.flaticon-download-button:before { content: "\f167"; }
.flaticon-download-button-1:before { content: "\f168"; }
.flaticon-download-to-smartphone:before { content: "\f169"; }
.flaticon-downwards-arrow-key:before { content: "\f16a"; }
.flaticon-drafts-evelope-button:before { content: "\f16b"; }
.flaticon-drop-down-arrow:before { content: "\f16c"; }
.flaticon-drop-down-round-button:before { content: "\f16d"; }
.flaticon-drop-up-arrow:before { content: "\f16e"; }
.flaticon-earth-grid-select-language-button:before { content: "\f16f"; }
.flaticon-ellipsis:before { content: "\f170"; }
.flaticon-email-inbox:before { content: "\f171"; }
.flaticon-emoticon-with-happy-face:before { content: "\f172"; }
.flaticon-end-call-button:before { content: "\f173"; }
.flaticon-enter-arrow:before { content: "\f174"; }
.flaticon-exit-to-app-button:before { content: "\f175"; }
.flaticon-expand-arrow:before { content: "\f176"; }
.flaticon-expand-button:before { content: "\f177"; }
.flaticon-facebook-logo:before { content: "\f178"; }
.flaticon-failed-sms:before { content: "\f179"; }
.flaticon-fast-forward-button:before { content: "\f17a"; }
.flaticon-favorite-heart-button:before { content: "\f17b"; }
.flaticon-favorite-heart-outline-button:before { content: "\f17c"; }
.flaticon-filled-circle:before { content: "\f17d"; }
.flaticon-filled-cloud:before { content: "\f17e"; }
.flaticon-filled-speaker-with-white-details:before { content: "\f17f"; }
.flaticon-film-roll:before { content: "\f180"; }
.flaticon-filter-results-button:before { content: "\f181"; }
.flaticon-flash-off:before { content: "\f182"; }
.flaticon-flash-on-indicator:before { content: "\f183"; }
.flaticon-folder-symbol:before { content: "\f184"; }
.flaticon-forward-arrow:before { content: "\f185"; }
.flaticon-forward-right-arrow-button:before { content: "\f186"; }
.flaticon-framed-portrait:before { content: "\f187"; }
.flaticon-front-bus:before { content: "\f188"; }
.flaticon-front-car:before { content: "\f189"; }
.flaticon-front-store:before { content: "\f18a"; }
.flaticon-full-screen-exit:before { content: "\f18b"; }
.flaticon-gamepad:before { content: "\f18c"; }
.flaticon-gamepad-1:before { content: "\f18d"; }
.flaticon-get-directions-button:before { content: "\f18e"; }
.flaticon-gmail-logo:before { content: "\f18f"; }
.flaticon-go-back-left-arrow:before { content: "\f190"; }
.flaticon-good-mood-emoticon:before { content: "\f191"; }
.flaticon-good-wifi-signal-with-three-bars:before { content: "\f192"; }
.flaticon-google-apps-script-logo:before { content: "\f193"; }
.flaticon-google-cast-logo:before { content: "\f194"; }
.flaticon-google-chrome-logo-filled-with-white-colour:before { content: "\f195"; }
.flaticon-google-drive-document:before { content: "\f196"; }
.flaticon-google-drive-file:before { content: "\f197"; }
.flaticon-google-drive-folder-symbol:before { content: "\f198"; }
.flaticon-google-drive-fusion-tables-logo:before { content: "\f199"; }
.flaticon-google-drive-image:before { content: "\f19a"; }
.flaticon-google-drive-logo:before { content: "\f19b"; }
.flaticon-google-drive-pdf-file:before { content: "\f19c"; }
.flaticon-google-drive-presentation:before { content: "\f19d"; }
.flaticon-google-drive-spreadsheet:before { content: "\f19e"; }
.flaticon-google-glass-logo:before { content: "\f19f"; }
.flaticon-google-hangouts-logo:before { content: "\f1a0"; }
.flaticon-google-hangouts-turn-video-off:before { content: "\f1a1"; }
.flaticon-google-plus-logo:before { content: "\f1a2"; }
.flaticon-google-plus-logo-on-black-background:before { content: "\f1a3"; }
.flaticon-google-plus-party-mode:before { content: "\f1a4"; }
.flaticon-google-symbol:before { content: "\f1a5"; }
.flaticon-gps-fixed-indicator:before { content: "\f1a6"; }
.flaticon-gps-location-disabled:before { content: "\f1a7"; }
.flaticon-gps-location-symbol:before { content: "\f1a8"; }
.flaticon-gps-off:before { content: "\f1a9"; }
.flaticon-graduate-cap:before { content: "\f1aa"; }
.flaticon-half-filled-rating-star:before { content: "\f1ab"; }
.flaticon-hangouts-videocall-button:before { content: "\f1ac"; }
.flaticon-headset:before { content: "\f1ad"; }
.flaticon-headset-with-microphone:before { content: "\f1ae"; }
.flaticon-help-button-speech-bubble-with-question-mark:before { content: "\f1af"; }
.flaticon-help-round-button:before { content: "\f1b0"; }
.flaticon-hide-keyboard-button:before { content: "\f1b1"; }
.flaticon-high-quality-button:before { content: "\f1b2"; }
.flaticon-high-signal-indicator:before { content: "\f1b3"; }
.flaticon-high-wifi-signal-indicator:before { content: "\f1b4"; }
.flaticon-history-clock-button:before { content: "\f1b5"; }
.flaticon-home-button:before { content: "\f1b6"; }
.flaticon-horizontal-line-remove-button:before { content: "\f1b7"; }
.flaticon-hotel-with-three-floors:before { content: "\f1b8"; }
.flaticon-https-symbol:before { content: "\f1b9"; }
.flaticon-import-export-arrows:before { content: "\f1ba"; }
.flaticon-insert-picture-button:before { content: "\f1bb"; }
.flaticon-instagram-logo:before { content: "\f1bc"; }
.flaticon-invert-colors-button:before { content: "\f1bd"; }
.flaticon-keyboard-left-arrow-button:before { content: "\f1be"; }
.flaticon-keyboard-right-arrow-button:before { content: "\f1bf"; }
.flaticon-keyboard-right-arrow-button-1:before { content: "\f1c0"; }
.flaticon-label-right-arrow-outline:before { content: "\f1c1"; }
.flaticon-landscape-with-mountains:before { content: "\f1c2"; }
.flaticon-laptop-outline:before { content: "\f1c3"; }
.flaticon-left-arrow-key:before { content: "\f1c4"; }
.flaticon-left-arrow-key-1:before { content: "\f1c5"; }
.flaticon-left-arrow-missed-call:before { content: "\f1c6"; }
.flaticon-light-bulb-on:before { content: "\f1c7"; }
.flaticon-link-button:before { content: "\f1c8"; }
.flaticon-linkedin-logo:before { content: "\f1c9"; }
.flaticon-list-button-with-3-elements:before { content: "\f1ca"; }
.flaticon-list-of-three-elements-on-black-background:before { content: "\f1cb"; }
.flaticon-location-arrow:before { content: "\f1cc"; }
.flaticon-locked-padlock:before { content: "\f1cd"; }
.flaticon-locked-padlock-outline:before { content: "\f1ce"; }
.flaticon-low-signal-indicator:before { content: "\f1cf"; }
.flaticon-magic-wand-auto-fix-button:before { content: "\f1d0"; }
.flaticon-man-cycling:before { content: "\f1d1"; }
.flaticon-man-walking-directions-button:before { content: "\f1d2"; }
.flaticon-map-placeholder:before { content: "\f1d3"; }
.flaticon-map-symbol:before { content: "\f1d4"; }
.flaticon-mark-as-favorite-star:before { content: "\f1d5"; }
.flaticon-mark-as-unread-envelope-button:before { content: "\f1d6"; }
.flaticon-medium-brightness-cogwheel:before { content: "\f1d7"; }
.flaticon-medium-signal:before { content: "\f1d8"; }
.flaticon-medium-wifi-signal-with-two-bars:before { content: "\f1d9"; }
.flaticon-memory-chip:before { content: "\f1da"; }
.flaticon-menu-button:before { content: "\f1db"; }
.flaticon-merge-calls-arrow:before { content: "\f1dc"; }
.flaticon-microphone-of-voice:before { content: "\f1dd"; }
.flaticon-microphone-voice-button:before { content: "\f1de"; }
.flaticon-microsoft-excel-logo:before { content: "\f1df"; }
.flaticon-microsoft-word-logo:before { content: "\f1e0"; }
.flaticon-missed-call-phone-receiver-with-left-arrow:before { content: "\f1e1"; }
.flaticon-mountain-range:before { content: "\f1e2"; }
.flaticon-mountain-range-on-black-background:before { content: "\f1e3"; }
.flaticon-mountains-inside-a-circle:before { content: "\f1e4"; }
.flaticon-mouse-filled-tool:before { content: "\f1e5"; }
.flaticon-move-window:before { content: "\f1e6"; }
.flaticon-new-tab:before { content: "\f1e7"; }
.flaticon-new-tab-button:before { content: "\f1e8"; }
.flaticon-nfc-near-field-communication:before { content: "\f1e9"; }
.flaticon-notifications-bell-button:before { content: "\f1ea"; }
.flaticon-notifications-button:before { content: "\f1eb"; }
.flaticon-notifications-silenced:before { content: "\f1ec"; }
.flaticon-pages-of-google-plus:before { content: "\f1ed"; }
.flaticon-painter-palette:before { content: "\f1ee"; }
.flaticon-panoramic-mountain-photography:before { content: "\f1ef"; }
.flaticon-parking-sign:before { content: "\f1f0"; }
.flaticon-pause-button:before { content: "\f1f1"; }
.flaticon-phone-call-button:before { content: "\f1f2"; }
.flaticon-phone-locked:before { content: "\f1f3"; }
.flaticon-phone-paused-indicator:before { content: "\f1f4"; }
.flaticon-phone-receiver-with-bluetooth-sign:before { content: "\f1f5"; }
.flaticon-phone-settings:before { content: "\f1f6"; }
.flaticon-phone-working-indicator:before { content: "\f1f7"; }
.flaticon-photo-album:before { content: "\f1f8"; }
.flaticon-photo-frame:before { content: "\f1f9"; }
.flaticon-photo-library:before { content: "\f1fa"; }
.flaticon-photographic-film-roll:before { content: "\f1fb"; }
.flaticon-pinterest-logo:before { content: "\f1fc"; }
.flaticon-planet-earth:before { content: "\f1fd"; }
.flaticon-play-arrow:before { content: "\f1fe"; }
.flaticon-play-button-inside-a-circle:before { content: "\f1ff"; }
.flaticon-play-next-button:before { content: "\f200"; }
.flaticon-play-rounded-button:before { content: "\f201"; }
.flaticon-play-slideshow-button:before { content: "\f202"; }
.flaticon-plus-one:before { content: "\f203"; }
.flaticon-poll-symbol-on-black-square-with-rounded-corners:before { content: "\f204"; }
.flaticon-power-connection-indicator:before { content: "\f205"; }
.flaticon-previous-track:before { content: "\f206"; }
.flaticon-printer-printing-document:before { content: "\f207"; }
.flaticon-problems-with-synchronization:before { content: "\f208"; }
.flaticon-progress-indicator:before { content: "\f209"; }
.flaticon-public-transport-subway:before { content: "\f20a"; }
.flaticon-public-transport-train-directions:before { content: "\f20b"; }
.flaticon-puzzle-piece-plugin:before { content: "\f20c"; }
.flaticon-radio-on-button:before { content: "\f20d"; }
.flaticon-random-line:before { content: "\f20e"; }
.flaticon-rate-star-button:before { content: "\f20f"; }
.flaticon-read-more-left-arrow-button:before { content: "\f210"; }
.flaticon-receipt:before { content: "\f211"; }
.flaticon-record-voice-microphone-button:before { content: "\f212"; }
.flaticon-refresh-button:before { content: "\f213"; }
.flaticon-refresh-button-1:before { content: "\f214"; }
.flaticon-reminder-bow:before { content: "\f215"; }
.flaticon-replay-arrow:before { content: "\f216"; }
.flaticon-reply-all-button:before { content: "\f217"; }
.flaticon-report-symbol:before { content: "\f218"; }
.flaticon-rewind-button:before { content: "\f219"; }
.flaticon-right-arrow-forward:before { content: "\f21a"; }
.flaticon-ring-volume-control-button:before { content: "\f21b"; }
.flaticon-rotate-to-left-button:before { content: "\f21c"; }
.flaticon-rotate-to-right-button:before { content: "\f21d"; }
.flaticon-round-account-button-with-user-inside:before { content: "\f21e"; }
.flaticon-round-add-button:before { content: "\f21f"; }
.flaticon-round-data-usage-symbol:before { content: "\f220"; }
.flaticon-round-delete-button:before { content: "\f221"; }
.flaticon-round-error-symbol:before { content: "\f222"; }
.flaticon-round-info-button:before { content: "\f223"; }
.flaticon-round-pause-button:before { content: "\f224"; }
.flaticon-round-remove-button:before { content: "\f225"; }
.flaticon-round-rgb-button:before { content: "\f226"; }
.flaticon-rounded-add-button:before { content: "\f227"; }
.flaticon-rounded-add-button-1:before { content: "\f228"; }
.flaticon-rounded-adjust-button-with-plus-and-minus:before { content: "\f229"; }
.flaticon-rounded-block-sign:before { content: "\f22a"; }
.flaticon-rounded-info-button:before { content: "\f22b"; }
.flaticon-rounded-pause-button:before { content: "\f22c"; }
.flaticon-rounded-remove-button:before { content: "\f22d"; }
.flaticon-rubbish-bin-delete-button:before { content: "\f22e"; }
.flaticon-save-button:before { content: "\f22f"; }
.flaticon-schedule-button:before { content: "\f230"; }
.flaticon-screen-locked:before { content: "\f231"; }
.flaticon-screen-locked-1:before { content: "\f232"; }
.flaticon-screen-rotation-button:before { content: "\f233"; }
.flaticon-screen-rotation-lock-button:before { content: "\f234"; }
.flaticon-screen-with-news-sections:before { content: "\f235"; }
.flaticon-screen-with-rounded-corners:before { content: "\f236"; }
.flaticon-sd-card:before { content: "\f237"; }
.flaticon-sd-card-1:before { content: "\f238"; }
.flaticon-searching-location-gps-indicator:before { content: "\f239"; }
.flaticon-searching-magnifying-glass:before { content: "\f23a"; }
.flaticon-select-all:before { content: "\f23b"; }
.flaticon-select-brightness-button:before { content: "\f23c"; }
.flaticon-send-button:before { content: "\f23d"; }
.flaticon-send-to-back-button:before { content: "\f23e"; }
.flaticon-send-to-front-button:before { content: "\f23f"; }
.flaticon-server-rack-with-three-levels:before { content: "\f240"; }
.flaticon-set-alarm:before { content: "\f241"; }
.flaticon-set-timer-button:before { content: "\f242"; }
.flaticon-settings-cogwheel-button:before { content: "\f243"; }
.flaticon-settings-cogwheel-inside-black-square:before { content: "\f244"; }
.flaticon-share-button:before { content: "\f245"; }
.flaticon-shared-folder:before { content: "\f246"; }
.flaticon-shining-rectangular-object:before { content: "\f247"; }
.flaticon-shining-sun:before { content: "\f248"; }
.flaticon-shopping-basket-button:before { content: "\f249"; }
.flaticon-shopping-cart:before { content: "\f24a"; }
.flaticon-show-apps-button:before { content: "\f24b"; }
.flaticon-show-less-fold-button:before { content: "\f24c"; }
.flaticon-show-menu-button:before { content: "\f24d"; }
.flaticon-show-more-button:before { content: "\f24e"; }
.flaticon-show-more-button-with-three-dots:before { content: "\f24f"; }
.flaticon-shuffle-mode-arrows:before { content: "\f250"; }
.flaticon-sim-card-problem:before { content: "\f251"; }
.flaticon-smartphone-dock:before { content: "\f252"; }
.flaticon-smartphone-with-three-buttons:before { content: "\f253"; }
.flaticon-sms-bubble-speech:before { content: "\f254"; }
.flaticon-sms-speech-bubble:before { content: "\f255"; }
.flaticon-snake-game:before { content: "\f256"; }
.flaticon-sort-button-with-three-lines:before { content: "\f257"; }
.flaticon-speech-bubble-with-mountains-inside:before { content: "\f258"; }
.flaticon-split-call-arrows:before { content: "\f259"; }
.flaticon-square-add-button:before { content: "\f25a"; }
.flaticon-stop-button:before { content: "\f25b"; }
.flaticon-swap-horizontal-orientation-arrows:before { content: "\f25c"; }
.flaticon-swap-vertical-orientation-arrows:before { content: "\f25d"; }
.flaticon-switch-camera-button:before { content: "\f25e"; }
.flaticon-switch-to-full-screen-button:before { content: "\f25f"; }
.flaticon-switch-vertical-orientation-arrows:before { content: "\f260"; }
.flaticon-switch-video-on:before { content: "\f261"; }
.flaticon-synchronization-arrows:before { content: "\f262"; }
.flaticon-synchronization-button-with-two-arrows:before { content: "\f263"; }
.flaticon-synchronization-problem:before { content: "\f264"; }
.flaticon-tab-symbol:before { content: "\f265"; }
.flaticon-tablet-with-blank-screen:before { content: "\f266"; }
.flaticon-tack-save-button:before { content: "\f267"; }
.flaticon-tag-button-with-happy-face:before { content: "\f268"; }
.flaticon-telephone-keypad-with-ten-keys:before { content: "\f269"; }
.flaticon-thermostat-temperature-wheel:before { content: "\f26a"; }
.flaticon-three-columns-layout:before { content: "\f26b"; }
.flaticon-three-dots-more-indicator:before { content: "\f26c"; }
.flaticon-thumb-down-button:before { content: "\f26d"; }
.flaticon-thumb-up-button:before { content: "\f26e"; }
.flaticon-tick-inside-circle:before { content: "\f26f"; }
.flaticon-time-lapse:before { content: "\f270"; }
.flaticon-traffic-light:before { content: "\f271"; }
.flaticon-train-public-transport:before { content: "\f272"; }
.flaticon-tumblr-logo:before { content: "\f273"; }
.flaticon-turn-airplane-mode-off:before { content: "\f274"; }
.flaticon-turn-microphone-off-button:before { content: "\f275"; }
.flaticon-turn-notifications-off-button:before { content: "\f276"; }
.flaticon-turn-notifications-on-button:before { content: "\f277"; }
.flaticon-turn-synchronization-off:before { content: "\f278"; }
.flaticon-turn-video-off-button:before { content: "\f279"; }
.flaticon-turn-visibility-off-button:before { content: "\f27a"; }
.flaticon-twitter-logo-on-black-background:before { content: "\f27b"; }
.flaticon-two-circles-one-inside-the-other:before { content: "\f27c"; }
.flaticon-two-men:before { content: "\f27d"; }
.flaticon-two-rows-and-three-columns-layout:before { content: "\f27e"; }
.flaticon-underline-button:before { content: "\f27f"; }
.flaticon-underline-text-button:before { content: "\f280"; }
.flaticon-undo-button:before { content: "\f281"; }
.flaticon-unlocked-padlock:before { content: "\f282"; }
.flaticon-up-arrow-key:before { content: "\f283"; }
.flaticon-upload-button:before { content: "\f284"; }
.flaticon-upload-to-cloud-button:before { content: "\f285"; }
.flaticon-usb-symbol:before { content: "\f286"; }
.flaticon-user-account-box:before { content: "\f287"; }
.flaticon-user-account-box-1:before { content: "\f288"; }
.flaticon-user-inside-bubble-speech:before { content: "\f289"; }
.flaticon-user-outline:before { content: "\f28a"; }
.flaticon-user-shape:before { content: "\f28b"; }
.flaticon-users-social-symbol:before { content: "\f28c"; }
.flaticon-verification-mark:before { content: "\f28d"; }
.flaticon-videocall-button:before { content: "\f28e"; }
.flaticon-videocam-filled-tool:before { content: "\f28f"; }
.flaticon-view-list-button:before { content: "\f290"; }
.flaticon-virtual-private-network-vpn-symbol:before { content: "\f291"; }
.flaticon-visibility-button:before { content: "\f292"; }
.flaticon-voice-message-microphone-button:before { content: "\f293"; }
.flaticon-voicemail:before { content: "\f294"; }
.flaticon-volume-down-indicator:before { content: "\f295"; }
.flaticon-volume-muted:before { content: "\f296"; }
.flaticon-volume-off-indicator:before { content: "\f297"; }
.flaticon-volume-up-indicator:before { content: "\f298"; }
.flaticon-warning-sign:before { content: "\f299"; }
.flaticon-watch-with-blank-face:before { content: "\f29a"; }
.flaticon-waving-flag:before { content: "\f29b"; }
.flaticon-web-server:before { content: "\f29c"; }
.flaticon-website-with-different-sections:before { content: "\f29d"; }
.flaticon-wifi-high-signal:before { content: "\f29e"; }
.flaticon-wifi-low-signal-with-one-bar:before { content: "\f29f"; }
.flaticon-wifi-signal-waves:before { content: "\f2a0"; }
.flaticon-window-with-different-sections:before { content: "\f2a1"; }
.flaticon-work-briefcase:before { content: "\f2a2"; }
.flaticon-workspace-with-two-horizontal-windows:before { content: "\f2a3"; }
.flaticon-world-shape-public-symbol:before { content: "\f2a4"; }
.flaticon-write-email-envelope-button:before { content: "\f2a5"; }
.flaticon-youtube-logo:before { content: "\f2a6"; }